import React from 'react'
import Layout from '../components/Layout'
import PageHero from '../components/PageHero'
import PricingCard from '../components/PricingCard'

export default () => (
  <Layout page="pricing" className="hero-distort-1">

    <PageHero title="Une facturation simple et transparente"
              content={
                <div>
                  Vitepay se rémunère lorsque votre business génère de l'activité.<br/>
                  Pas besoin d'investissement lourd en amont, nous souhaitons avant tout que votre activité se
                  développe.
                </div>
              }/>

    <div className="clearfix container-fluid" style={{ padding: '0 200px' }}>
      <div className="row">
        <div className="col-4">
          <PricingCard title="Checkout" pricing={'2%'} pricingDek="par paiement réussi"
                       sellingPoints={
                         ['Pas de frais d\'installation, ni de frais mensuels ou cachés',
                           'Reporting des commissions en temps réel']}
          />
        </div>
        <div className="col-4">
          <PricingCard title="Boutik'" pricing={'30000'}
                       pricingDek="FCFA/mois + 2% par paiement réussi"
                       sellingPoints={
                         ['Aucune installation à effectuer',
                           'Bénéficiez de Vitepay Livraison',
                           'Vous avez juste besoin d\'une connexion internet']}/>
        </div>
        <div className="col-4">
          <PricingCard title="Livraison" pricing={'0'}
                       pricingDek="Pas de frais pour les marchands"
                       sellingPoints={
                         ['Bénéficiez du réseau de partenaires Vitepay',
                           'Suivez en temps réel l\'état de la livraison']}/>
        </div>
      </div>
      <div className="row">
        <div className="col-4 offset-2">
          <PricingCard title="Centrale" pricing={'Contact'}
                       pricingDek="Collaboration à la demande"
                       sellingPoints={
                         ['Evaluation de votre besoin',
                           'Proposition de la meilleure formule']}/>
        </div>
        <div className="col-4">
          <PricingCard title="Paypal" pricing={'8%'}
                       pricingDek="par virement"
                       sellingPoints={
                         ['Effectuez votre demande de virement depuis votre compte mobile money',
                           'Recevez la confirmation Paypal au bout d\'1h']}/>
        </div>
      </div>
    </div>

  </Layout>
)
