import React from 'react'
import styled from 'styled-components'

export default ({title, content, align, image}) => (
  <Root>
    <div className="clearfix wrapper">
      <div className={`text-${ align || 'center' }`}>
        <h1>{title}</h1>
        <h2 className="content">
          {content}
        </h2>
      </div>
    </div>
  </Root>
)

const Root = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-position: center 0;
  
  .wrapper {
    padding: 80px 0 60px;
    margin: auto;
    width: 50%;
  }
  
  h1 {
    font-size: 3.8em;
    letter-spacing: -1px;
    font-weight: 800;
  }
  h2 {
    font-size: 2em;
    font-weight: 400;
  }
  .content {
    padding: 10px 0 30px;   
    font-size: 1.8em;
    line-height: 1.4;
  }
`
