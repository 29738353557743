import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

const star = require('../resources/img/plan-star.svg')

export default ({ title, description, pricing, pricingDek, sellingPoints, cta }) => (
  <Root>
    <div className="clearfix header spacing">{title}</div>
    <div className="clearfix pricing spacing">{pricing}</div>
    <div className="clearfix pricing-dek spacing">{pricingDek}</div>
    {sellingPoints && <div className="clearfix description spacing">{
      <ul>
        {sellingPoints.map((sp, index) => (
          <li key={index}>
            <img src={star} width={20} alt="" /> {sp}
            </li>
        ))}
      </ul>
    }</div>}
    {cta && <div className="clearfix cta spacing"><Link to={cta.to} title="">{cta.content}</Link></div>}
  </Root>
)

const Root = styled.div`
  box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
  background: #FFF;
  border-radius: 4px;
  overflow: hidden;
  margin: 50px 0;
  height: 450px;
  
  .spacing {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .header {
    text-align: center;
    border-bottom: 2px solid #F6F9FC;
    color: #3C1E9B;
    font-weight: 600;
    font-size: 2em;
    padding: 25px 0;
  }
  
  .pricing {
    text-align: center;
    color: #32325d;
    font-size: 4.8em;
    font-weight: 500;
    padding-top: 40px;
    padding-bottom: 0;
    line-height: 1;
  }
  
  .pricing-dek {
    text-align: center;
    color: #8898AA;
    padding-top: 10px;
    font-size: 1.2em;
  }
  
  .description {
    padding-top: 20px;
  }
  
  .description ul li {
    display: block;
    color: #525f7f;
    font-size: 1.05em;
    font-weight: 400;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 10px;
    
    img {
      float: left;
      margin-left: -30px;
    }
  }
  
  
`
